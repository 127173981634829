import React from 'react';

import { Container, useMediaQuery } from '@material-ui/core';

// import { CompanyCard } from './UpperSectionComponents/CompanyCard';
// import { CompanyCallout } from './UpperSectionComponents/CompanyCallout';
import { CompanyCenterText } from './UpperSectionComponents/CompanyCenterText';
import { CompanyCenterWave } from './UpperSectionComponents/CompanyCenterWave';
import { CompanyFeature } from './UpperSectionComponents/CompanyFeature';

export const CompanyUpperSection = ({ companyUpperSection }) => {
	const {
		_rawCenterTextTop,
		companyFeatures,
		_rawCenterTextBottom,
		centerWaveBackground,
		centerWaveMobile,
		centerWaveDesktop,
		cardHeaderOverlay,
		cardHeaderOverlayMobile,
	} = companyUpperSection;

	const md = useMediaQuery('(max-width: 960px)');
	return (
		<>
			<Container>
				<CompanyCenterText content={_rawCenterTextTop} />
				{/* <CompanyCard
					background={
						md
							? cardHeaderMobile?.asset.gatsbyImageData.images.fallback.src
							: cardHeader?.asset.gatsbyImageData.images.fallback.src
					}
					desktop={cardHeaderOverlay?.asset.gatsbyImageData}
					mobile={cardHeaderOverlayMobile?.asset.gatsbyImageData}
					content={_rawCardContent}
				/>
				<CompanyCallout
					content={_rawCalloutText}
					image={calloutImage.asset.gatsbyImageData}
				/> */}
				{companyFeatures.map((feature, index) => (
					<CompanyFeature key={index} feature={feature} index={index} />
				))}
				<CompanyCenterText content={_rawCenterTextBottom} lower />
			</Container>
			<CompanyCenterWave
				background={centerWaveBackground}
				stats={md ? centerWaveMobile : centerWaveDesktop}
			/>
		</>
	);
};
